@import url("../ShopTheme.css");

body {
  color: #333;
  font-weight: 300;
  line-height: 1.57142857;
}
body,
html {
  /*    font-size: 10px;*/
  height: 100%;
}

.productPage .imagesContainer {
  width: 300px;
  float: left;
}

.productPage .imagesContainer .product-name {
  display: none;
}
.productPage .dataContainer {
  width: calc(100% - 300px);
  padding-left: 30px;
  float: left;
}

@media (max-width: 600px) {
  .slide-container {
    width: 400px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .web-product-images {
    width: 400px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 450px) {
  .slide-container {
    width: 300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .web-product-images {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 768px) {
  .productPage {
    text-align: center;
  }
  .productPage .imagesContainer .product-name {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .productPage .dataContainer .product-name {
    display: none;
  }
  .productPage .imagesContainer {
    width: 90%;
  }
  .productPage .dataContainer {
    width: 100% !important;
    padding: 0;
    padding-top: 30px;
  }
}
.productPage .web-product-images {
  padding-top: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.productPage .nav-tabs-line > li > a,
.productPage .nav-tabs-line > li > a:focus,
.productPage .nav-tabs-line > li > a:hover {
  border-bottom: 2px solid transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #fff;
  background-color: #00897b;
  border-color: transparent;
  border-bottom-color: #00897b;
}

.productPage .web-category-link-sepparator {
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.productPage .web-category-link {
  text-transform: uppercase;
  color: black;
}

.productPage .imagesContainer {
  width: 40%;
}

.productPage .dataContainer {
  width: calc(100% - 40%);
}

@media (max-width: 768px) {
  .productPage .imagesContainer {
    width: 100%;
  }
}

.productPage .dataContainer .productPrice {
  padding-top: 2px;
  padding-bottom: 7px;
}

.productPage .nav.nav-tabs.nav-tabs-line {
  font-size: 12px;
  text-transform: uppercase;
}

.productPage .nav-tabs-line > li.active > a {
  color: #3a3a3a;
  border-bottom: 1px solid #3a3a3a;
}

.productPage .addProductToCart {
  padding: 5px 15px;
  font-size: 14px;
  color: #fff;
  background-color: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  font-weight: 600;
  margin-top: 15px;
  border-radius: 0px;
}

.productPage .addProductToCart:hover {
  background-color: var(--shop-theme-color-hover);
  border-color: var(--shop-theme-color-hover);
}

.productPage .nav-tabs {
  border-bottom: 1px solid #f3f3f3;
}

.productPage #descriptionTab {
  color: #3a3a3a;
  white-space: break-spaces;
  font-size: 15px;
}
.productPage #deliveryTab {
  font-size: 15px;
}

#checkoutProductsPanel h4 {
  font-size: 18px;
}

.productsResults {
  font-family: var(--shop-font-family), var(--shop-font-family), sans-serif;
}

.productsResults .dataContainer .nav-item a {
  color: #333;
  text-decoration: none;
  transition: none;
  border: none;
}

.productsResults .dataContainer .nav-item a.active {
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid var(--shop-theme-color);
}

.productsResults .dataContainer .product-name {
  margin-bottom: 0;
  font-size: 1.7rem !important;
  font-weight: 600;
  padding: 0px 20px;
}

.productsResults .dataContainer .productPrice {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 11px;
  padding-bottom: 1.75rem;
  padding-bottom: 0px;
  font-size: 1.75rem;
  font-size: 17.5px;
  font-weight: 600;
  color: #333;
}

.productsResults .imagesContainer .product-name {
  display: none !important;
}
.buyerModal *,
#checkoutDeliveryMethodModal *,
.paymentMethodModal *,
.checkoutConfirmModal *,
.selectPaymentMethodModal * {
  font-family: var(--shop-font-family), sans-serif !important;
}
.buyerModal .modal-header,
#checkoutDeliveryMethodModal .modal-header,
.checkoutConfirmModal .modal-header,
.paymentMethodModal .modal-header,
.selectPaymentMethodModal .modal-header {
  background-color: #f9f9f9;
}

.modal-header .btn-close {
  height: 15px;
  width: 15px;
}

.buyerModal button[type="submit"],
#checkoutDeliveryMethodModal button[type="submit"],
.checkoutDeliveryButton,
#checkoutPaymentmethodPanel .btn-round,
.paymentMethodModal .btn,
.checkoutConfirmModal .btn,
.selectPaymentMethodModal .btn {
  font-size: 15px;
  padding: 6px 15px;
}

.checkoutDeliveryButton {
  border: 1px solid var(--shop-theme-color);
  font-weight: 600;
  color: var(--shop-theme-color);
  border-radius: var(--shop-border-radius);
}

.btn {
  font-size: 14px !important;
}

.checkoutDeliveryButton:hover {
  background-color: var(--shop-theme-color);
  color: white;
}

.buyerModal input {
  font-size: 14px !important;
}

#checkoutDeliveryMethodModal .MuiTypography-root {
  font-size: 15px;
}

.product-page a {
  color: #333;
}

.MuiAutocomplete-input,
.MuiAutocomplete-option {
  font-size: 14px !important;
}

.custom-checkbox-root .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
}

.product-page a.breadcrumb {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 15px;
}

.product-page a.breadcrumb * {
  font-size: 15px;
  vertical-align: middle;
}

.page-header .breadcrumb {
  padding: 0;
  margin: 0;
}

.page-header {
  padding-bottom: 10px;

  border-bottom: 1px solid transparent;
}

.container-fluid.page-header-title {
  padding: 0;
}

.page-header {
  position: relative;

  margin-top: 0;
  margin-bottom: 0;
  background: 0 0;
  border-bottom: none;
}

@media (min-width: 961px) {
  .page-detail-page-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

@media (max-width: 960px) {
  .page-detail-page-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.light-gray-background {
  background: #f1f4f5;
  background-color: #f1f4f5 !important;
}
.light-gray-background-2 {
  background: #f9f9f9;
  background-color: #f9f9f9;
}

.nav-tabs-horizontal {
  margin: 30px !important;
}

.page-detail-page-header {
  margin: 0 20px !important;
}

@media (min-width: 576px) {
  .nav-tabs-horizontal {
    margin: 0px !important;
  }

  .page-detail-page-header {
    margin: 0px !important;
  }

  .prana .d-sm-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 768px) {
  .prana .container {
    max-width: inherit;
  }

  .productsResults .dataContainer .product-name {
    margin-bottom: 0;
    font-size: 1.7rem !important;
    font-weight: 600;
    padding: 0px 0px;
  }

  .discount_price_box {
    margin-left: 0px !important;
  }

  .productsResults .dataContainer .productPrice {
    justify-content: left !important;
  }

  .prana .dataContainer {
    padding-left: 2rem !important;
  }
  .prana .contact-page form {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .prana .container {
    max-width: inherit;
  }
  .prana .d-md-none {
    display: none;
  }
}

@media (min-width: 1200px) {
  .prana .container {
    max-width: 1300px;
  }
}

.web-category-link-sepparator {
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.web-category-link {
  text-transform: uppercase;
  color: black;
}

.product-detail-page-nav {
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin-bottom: 0;
  font-family: var(--shop-font-family), sans-serif;
}

.productsResults .panel .panel-body {
  padding: 30px 0 !important;
}

.shop-controlled-border-radius {
  border-radius: var(--shop-border-radius);
}

.discount_price_box {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;

  border: 1px dashed;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 11px;
  font-size: 15px;
  font-weight: 500;
}

.product-variants {
  margin-top: 15px;
}

.product-variant {
  display: flex;
  flex-direction: column;
}

.product-variant-values {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.product-variant-value {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border: 1px solid var(--shop-theme-color) !important;
  background-color: transparent !important;
  color: var(--shop-theme-color) !important;
}

.product-variant-value-selected {
  background-color: var(--shop-theme-color) !important;
  color: #fff !important;
}

.product-variant-value:disabled {
  cursor: not-allowed !important;
  text-decoration: line-through 1px #949494;
  background-color: transparent !important;
  color: #949494 !important;
  border: 1px solid #949494 !important;

  opacity: 0.5;
}

.addProductVariableToCart {
  border: 1px solid var(--shop-theme-color) !important;
  background: var(--shop-theme-color) !important;
  color: #fff !important;
}

.addProductVariableToCart:hover {
  background-color: var(--shop-theme-color-hover) !important;
  border-color: var(--shop-theme-color-hover) !important;
}

.addProductVariableToCart:disabled {
  cursor: not-allowed !important;
  text-decoration: line-through 1px #949494;
  background-color: transparent !important;
  color: #949494 !important;
  border: 1px solid #949494 !important;

  opacity: 0.5;
}
